import { useMatch } from '@reach/router'
import { getBasePath } from '../utils/route'

/**
 * @returns The tenant from the URL. (if it exists)
 */
export const useTenant = () => {
  const match = useMatch(`${getBasePath()}:tenant/*`)

  return match?.tenant ?? null
}
